import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { signUp } from 'aws-amplify/auth';
import '../assets/css/Signin.css';
import HeaderLogin from './HeaderLogin';
import '../assets/css/SemicirclesLogin.css';
import Links from '../util/Links';

const Signup = () => {
    const navigate = useNavigate();

    const handleSignUp = async (values, actions) => {
        const { email, password } = values;
        try {
            const { userSub } = await signUp({
                username: email,
                password,
                options: {
                    userAttributes: {
                        email,
                    },
                    autoSignIn: true,
                },
            });
            console.log(userSub);
            navigate(Links.confirmSignup, { state: { username: email } }); // the username is passed as a state
        } catch (error) {
            console.error('error signing up:', error);
            actions.setFieldError('general', error.message || 'failed to sign up');
        }
    };

    return (
        <div className="affective-forecaster-login">
            <div className="container">
                <HeaderLogin />
                <div className="semi-circle-left-signup"></div>
                <div className="semi-circle-right-signup"></div>
                <h1 className="signin-signinpage-title">Create Account</h1>
                <Formik
                    initialValues={{ email: '', password: '', confirm: '' }}
                    onSubmit={handleSignUp}
                >
                    {({ errors }) => (
                        <div className="form-container">
                            <div className="form-section">
                                <Form>
                                    <div className="input-group">
                                        <label>Email:</label>
                                        <Field type="text" name="email" />
                                    </div>
                                    <div className="input-group">
                                        <label>Password:</label>
                                        <Field type="password" name="password" />
                                    </div>
                                    <div className="input-group">
                                        <label>Confirm Password:</label>
                                        <Field type="password" name="confirm" />
                                    </div>
                                    {errors.general && <div className="error">{errors.general}</div>}
                                    <button type="submit" className="login-button">
                                        Sign Up
                                    </button>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Signup;
