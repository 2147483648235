import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { confirmSignUp } from 'aws-amplify/auth';
import '../assets/css/Signin.css';
import HeaderLogin from './HeaderLogin';
import '../assets/css/SemicirclesLogin.css';
import Links from '../util/Links';
import { useLocation } from 'react-router-dom';

const ConfirmSignup = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { username } = location.state || {};
    const handleConfirmSignUp = async (values, actions) => {
        const { confirmationCode } = values;
        try {
            const { isSignUpComplete, nextStep } = await confirmSignUp({ username, confirmationCode });
            console.log(isSignUpComplete);
            if (isSignUpComplete) {
                navigate(Links.signin);
            } else {
                console.log(nextStep);

            }
        } catch (error) {
            console.error('error confirming sign up:', error);
            actions.setFieldError('general', error.message || 'failed to confirm sign up');
        }
    };

    return (
        <div className="affective-forecaster-login">
            <div className="container">
                <HeaderLogin />
                <div className="semi-circle-left-signup"></div>
                <div className="semi-circle-right-signup"></div>
                <h1 className="signin-signinpage-title">Enter confirmation code</h1>
                <Formik
                    initialValues={{ confirmationCode: '' }}
                    onSubmit={handleConfirmSignUp}
                >
                    {({ errors }) => (
                        <div className="form-container">
                            <div className="form-section">
                                <Form>
                                    <div className="input-group">
                                        <label>Confirmation Code:</label>
                                        <Field type="text" name="confirmationCode" />
                                    </div>
                                    {errors.general && <div className="error">{errors.general}</div>}
                                    <button type="submit" className="login-button">
                                        Confirm
                                    </button>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ConfirmSignup;
