import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import 'chartjs-plugin-lineheight-annotation';

/**
 * The chart is adapted from ChartJS https://www.chartjs.org/docs/latest/charts/line.html
 */
// ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function SentimentChart({ eventsData }) {

    ChartJS.defaults.font.family = 'Comfortaa, cursive';
    ChartJS.defaults.color = '#4a4a4a';

    const emotionColors = {
        joy: 'rgba(43,164,10,0.56)',
        anger: 'rgba(239,0,47,0.81)',
        sadness: 'rgba(34,108,159,0.75)',
        fear: 'rgba(243,100,0,0.72)',
        disgust: 'rgba(255,220,6,0.78)',
    };

    // Setting up chart data
    const emotionLabels = eventsData.length > 0 ? Object.keys(eventsData[0].emotions) : [];
    const datasets = emotionLabels.map(emotion => ({
        label: emotion,
        data: eventsData.map(event => event.emotions[emotion]),
        borderColor: emotionColors[emotion] || 'rgba(0, 0, 0, 0.01)',
        tension: 0.4,
        fill: true,
        backgroundColor: (context) => {
            const { chart } = context;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
                return null;
            }

            const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0, emotionColors[emotion] || 'rgba(0, 0, 0, 0.01)');
            gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

            return gradient;
        },
    }));

    const chartData = {
        labels: eventsData.map((_, index) => `Event ${index + 1}`),
        datasets: datasets,
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    callback: function (value, index, values) {
                        if (index === 0) {
                            return 'Low';
                        } else if (index === values.length - 1) {
                            return 'High';
                        } else if (index === Math.floor(values.length / 2)) {
                            return 'Mid';
                        }
                        return '';
                    },
                    color: 'black',
                },
            },
        },
        plugins: {
            legend: {
                labels: {
                    color: 'black',
                },
            },
            lineHeightAnnotation: {
                lineHeight: 8,
                lineColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        return null;
                    }

                    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                    gradient.addColorStop(0, 'rgba(0, 0, 0, 0.2)');
                    gradient.addColorStop(0.5, 'rgba(0, 0, 0, 0.05)');
                    gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

                    return gradient;
                },
            },
        },
        elements: {
            line: {
                borderWidth: 3,
                borderCapStyle: 'round',
                borderJoinStyle: 'round',
            },
            point: {
                radius: 0,
            },
        }
    };

    return (
        <div className="sentiment-chart-container" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
            <Line data={chartData} options={chartOptions} />
        </div>
    );
}

export default SentimentChart;
