import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import '../assets/css/Header.css';
import Links from "../util/Links";

const Header = () => {
    const navigate = useNavigate();
    const { signOut } = useAuthenticator((context) => [context.signOut]);

    const goToHomePage = () => {
        navigate(Links.home);
    };

    const handleSignOut = async () => {
        try {
            await signOut(); // Attempt to sign out
        } catch (error) {
            console.error('Error signing out: ', error);
        }
        navigate('/');
    };

    return (
        <div className="header">
            <h1 onClick={goToHomePage} style={{cursor: 'pointer'}}>Affective Forecaster</h1>
            <button onClick={handleSignOut} className="sign-out-btn" aria-label="Sign out">Sign Out</button>
        </div>
    );
};

export default Header;
