import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../assets/css/HomePage.css';
import '../assets/css/Semicircles.css';
import '../assets/css/SentimentChart.css';
import { useAuthenticator } from "@aws-amplify/ui-react";
import Header from './Header';

/**
 * The user enters their expectation and scenario in the input fields on the home page.
 */

const HomePage = () => {
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const [expectation, setExpectation] = useState('');
    const [scenario, setScenario] = useState('');
    const navigate = useNavigate();
    // console.log("User:", user)

    //basic validation, sends data to waiting page and navigates to waiting page
    const handleSubmit = async (e) => {
        e.preventDefault();

        // trim input
        const trimmedScenario = scenario.trim();

        // check if the scenario contains at least one word
        if (!/\b\w+\b/.test(trimmedScenario)) {
            alert("Please enter a scenario");
            return;
        }

        // additional validation to ensure no links or invalid characters are present
        if (/(http:\/\/|https:\/\/|www\|[^\w\s!])/gi.test(trimmedScenario)) {
            alert("Please ensure the input does not contain any links or invalid characters");
            return;
        }

        // check if the word count exceeds 2500 words
        const wordCount = trimmedScenario.split(/\s+/).length; // Split by whitespace and count
        if (wordCount > 2500) {
            alert("Please keep scenario less than 2,500 words");
            return;
        }

        // Data to be passed to the waiting page and eventually used for the API call
        const submissionData = {
            scenario: trimmedScenario, // trimmed version to ensure no extra whitespace
            expectation: expectation,
            userId: user.userId
        };

        // Navigate to the waiting page and pass the submission data as state
        navigate('/waiting', { state: submissionData });
    };



    const clearUserData = async () => {
        try {
            // POST request clears the user data and resets the update count on the server
            await axios.post('/api/user/clearData', { userId: user.userId });
            alert('Personalisation factor data cleared successfully');
            // Fetch the updated count which should be reset after clear
            await fetchUpdateCount();
        } catch (error) {
            console.error('Error clearing data:', error);
            alert('Failed to clear data.');
        }
    };


    const [updateCount, setUpdateCount] = useState(0);
    const fetchUpdateCount = async () => {
        try {
            const { data } = await axios.get(`/api/user/${user.userId}/updateCount`);
            setUpdateCount(data.updateCount);
        } catch (error) {
            console.error('Error fetching update count for personalisation:', error);
        }
    };

    useEffect(() => {


        fetchUpdateCount();
    }, [user.userId]); // Re-run when userId changes
//

    return (
        <div className="affective-forecaster-home">
            <div className="semi-circle-left-home"></div>
            <div className="semi-circle-right-home"></div>
            <div className="container">
                <Header />
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>Enter what you expect from your scenario</label>
                        <textarea
                            className="expectation-input"
                            value={expectation}
                            onChange={(e) => setExpectation(e.target.value)}
                            placeholder="Example expectation: 'I want to feel happy.'"
                        />
                    </div>
                    <div className="input-group">
                        <label>Enter scenario for analysis</label>
                        <textarea
                            className="scenario-input"
                            value={scenario}
                            onChange={(e) => setScenario(e.target.value)}
                            placeholder="Example scenario: 'I want to be a barber in five years time. At the moment I'm a computer scientist living the mundane life of doing things I enjoy and making fun programs. But being a barber has been a dream I've had for the last three days. I tend to be impulsive.'"
                        />
                    </div>
                    <button type="submit">Submit for analysis</button>
                </form>
                <div className="updates-and-clear-home">
                    <div className="personalization-updates">Personalisation factor: {updateCount}</div>
                    <button type="button" onClick={clearUserData} className="clear-data-btn">Clear Data</button>
                </div>
            </div>
        </div>
    );
};




export default HomePage;
