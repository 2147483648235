import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import '../assets/css/WaitingPage.css';
import '../assets/css/Semicircles.css';
import { useAuthenticator } from "@aws-amplify/ui-react";
import Header from './Header';
import EmotionAnimation from "./EmotionAnimation";

/**
 * The waiting page receives the submissionData object from the home page through the useLocation hook.
 * The useEffect hook is used to fetch data from the server by making a POST request to /api/submit with the user's scenario, expectation, and user ID.
 * While waiting for the response, a countdown timer is displayed on the waiting page.
 * Once the response is received from the server, the navigate function is used to navigate to the results page (/results) and pass the response data as state.
 * The animation is adapted from: https://www.webbae.net/post/gsap-vs-webflow-for-website-animations
 */

const WaitingPage = () => {
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);

    const [countdown, setCountdown] = useState(33);

    // console.log("user:", user)

    useEffect(() => {
        // Countdown timer logic
        const timer = countdown > 0 && setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);


        return () => {
            clearInterval(timer);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            if (state) {
                try {
                    const response = await axios.post('/api/submit', {
                        userId: state.userId,
                        scenario: state.scenario,
                        expectation: state.expectation,
                    }, {
                        headers: { 'Content-Type': 'application/json' },
                    });
                    console.log("Received data:", response.data);
                    // console.log("state.user:", state.userId);


                    // After fetching, navigate to results page with the response data
                    navigate('/results', { state: { data: response.data } });
                } catch (error) {
                    console.error('Error submitting data:', error);
                    // TODO - route to home or something
                }
            }
        };

        if (loading) {
            fetchData().then(() => setLoading(false));
        }
    }, [navigate, state, loading]); // Adds state and loading to the dependency array

    return (

        <div className="affective-forecaster-home">
            {/*<div className="drop"></div>*/}
            {/*<div className="ripple"></div>*/}
            <div className="semi-circle-left-home"></div>
            <div className="semi-circle-right-home"></div>
            <div className="container">
                <Header />
                <div className="input-group">
                    <label>Running scenario</label>
                    {/*<h2>Running scenario</h2>*/}
                    <p>Estimated time remaining: {countdown} seconds</p>
                    <EmotionAnimation />  {/* animation */}
                </div>
            </div>
        </div>
    );
};

export default WaitingPage;
