import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn } from 'aws-amplify/auth';
import '../assets/css/Login.css';
import HeaderLogin from './HeaderLogin';
import '../assets/css/SemicirclesLogin.css';
import Links from "../util/Links";
import loginImage from '../assets/images/loginImage.png';


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSignUp = (e) => {
        e.preventDefault();
        navigate(Links.signup);
    };

    return (
        <div className="affective-forecaster-login">
            <HeaderLogin />
            <div className="container">
                <div className="login-content-wrapper">
                    <img src={loginImage} alt="Description" className="login-image" />
                    <div className="text-and-signup">
                        <div className="input-group-signup">
                            <div className="label-container">
                                <label>Don't have an account?</label>
                            </div>
                            <div className="button-container">
                                <button onClick={handleSignUp} className="signup-btn">Sign up</button>
                            </div>
                        </div>
                        <p className="description">
                            Affective forecaster is a tool that helps you understand the implications of what comes next in your life. It breaks down a user-defined scenario into events. Each event is analysed and sentiment analysis is conducted to help you predict how may like, or dislike your given scenario. Your affective journey based off the five primal emotions is outlined for you in an easily readable illustration.
                        </p>
                    </div>
                </div>
            </div>
            <div className="semi-circle-left-home"></div>
            <div className="semi-circle-right-home"></div>
        </div>
    );
};

export default Login;
