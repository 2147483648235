import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/css/HeaderLogin.css';
import { getCurrentUser } from "aws-amplify/auth";
import Links from "../util/Links";

const HeaderLogin = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [isSignedOut, setIsSignedOut] = useState(null);
    const [isSignedIn, setIsSignedIn] = useState(false);

    const goToHomePage = () => {
        isSignedIn ? navigate(Links.home) : navigate('/'); //TODO fix this
    };

    useEffect(() => {
        getCurrentUser()
            .then((currentUser) => {
                setUser(currentUser);
                setIsSignedOut(!currentUser || currentUser.userId === undefined);
                setIsSignedIn(!!currentUser && currentUser.userId !== undefined);
            })
            .catch((_) => {
                setIsSignedOut(true);
                setIsSignedIn(false);
            });
    }, []);

    return (

        <div className="header">
            <h1 onClick={goToHomePage} style={{ cursor: 'pointer' }}>Affective Forecaster</h1>
            {isSignedOut && (
                <Link to={Links.signin} className="signin-button">
                    Sign In
                </Link>
            )}
            {isSignedIn && (
                <Link to={Links.home} className="signin-button">
                    Run Scenario
                </Link>
            )}
        </div>
    );
};

export default HeaderLogin;
