import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import '../assets/css/EmotionAnimation.css';

/**
 *
 * Animation adapted from: https://www.webbae.net/post/gsap-vs-webflow-for-website-animations
 */
const EmotionAnimation = () => {
    const svgRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(MotionPathPlugin);

        // Function to create and animate a circle
        function createAndAnimateCircle(color, pathId) {
            const svg = svgRef.current;
            const ns = "http://www.w3.org/2000/svg";

            // Create a new circle element
            let newCircle = document.createElementNS(ns, "circle");
            newCircle.setAttributeNS(null, "r", "3.5");
            newCircle.setAttributeNS(null, "fill", color);

            // Append the circle to the SVG
            svg.appendChild(newCircle);

            // Animate the circle along the path
            gsap.to(newCircle, {
                duration: 15,
                repeat: -1,
                ease: "none",
                motionPath: {
                    path: pathId,
                    align: pathId,
                    autoRotate: true,
                    alignOrigin: [0.5, 0.5]
                }
            });
        }

        // Start animating circles along each path
        function startAnimating() {
            createAndAnimateCircle('rgba(43,164,10,0.56)', "#target-path-green");
            createAndAnimateCircle('rgba(34,108,159,0.75)', "#target-path-blue");
            createAndAnimateCircle('rgba(239,0,47,0.81)', "#target-path-red");
            createAndAnimateCircle('rgba(243,100,0,0.72)', "#target-path-orange");
            createAndAnimateCircle('rgba(255,220,6,0.78)', "#target-path-yellow");
        }

        // Set an interval for adding new circles
        const animationInterval = setInterval(startAnimating, 200);

        // Clean up the interval on component unmount
        return () => {
            clearInterval(animationInterval);
        };
    }, []);

    return (
        <section className='circular-animation'>
            <div className="padding-global">
                <div className="container-small">
                    <div id="trigger" className="padding-section-small">
                        <svg ref={svgRef} width="100%" height="300px" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="target-path-green" d="M0,50 C150,-100 300,200 450,50 C600,-100 750,200 900,50" stroke="#ececec" strokeOpacity="0.1" strokeWidth="0.1" strokeDasharray="1"/>
                            <path id="target-path-blue" d="M0,100 C125,50 250,150 375,100 C500,50 625,150 750,100" stroke="#ececec" strokeOpacity="0.1" strokeWidth="0.1" strokeDasharray="1"/>
                            <path id="target-path-red" d="M0,140 C150,100 300,180 450,140 C600,100 750,180 900,140" stroke="#ececec" strokeOpacity="0.1" strokeWidth="0.1" strokeDasharray="1"/>
                            <path id="target-path-orange" d="M0,180 C200,230 400,130 600,180 C800,230 1000,130 1200,180" stroke="#ececec" strokeOpacity="0.1" strokeWidth="0.1" strokeDasharray="1"/>
                            <path id="target-path-yellow" d="M0,220 C100,270 200,170 300,220 C400,270 500,170 600,220 C700,270 800,170 900,220" stroke="#ececec" strokeOpacity="0.1" strokeWidth="0.1" strokeDasharray="1"/>
                        </svg>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EmotionAnimation;
