import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Results from './components/Results';
import Signup from "./components/Signup";
import ConfirmSignup from "./components/ConfirmSignup";
import WaitingPage from "./components/WaitingPage";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Signin from "./components/Signin";
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
Amplify.configure(config);

function ProtectedRoutes() {
    return (
        <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/results" element={<Results />} />
            <Route path="/waiting" element={<WaitingPage />} />
        </Routes>
    );
}

const ProtectedRoutesWithAuthenticator = withAuthenticator(ProtectedRoutes);

function App() {
    console.log('App rendering');
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/signin" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/confirmSignup" element={<ConfirmSignup />} />
                <Route path="/" element={<Login />} />
                <Route path="/*" element={<ProtectedRoutesWithAuthenticator />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
