import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { useAuthenticator } from "@aws-amplify/ui-react";
import Header from './Header';
import '../assets/css/Semicirclesresults.css';
import '../assets/css/SentimentChart.css';
import '../assets/css/Results.css';
import SentimentChart from './SentimentChart';
import Links from "../util/Links";
/**
 * Explanation on handling data from backend and showing in Results.js:
 *
 * The Results page displays data about user scenarios and emotions, which it gets from the WaitingPage using the useLocation hook.
 *
 * The useEffect hook manages the data handling:
 *   1. Checking Data: First, it checks if the data is there in the location's state. If it's missing, it logs an error and stops further processing to avoid errors from undefined data.
 *   2. Parsing Data: If data is available, it processes each item in the 'data' array found in the location state. Each item is an event that includes an 'emotions' string.
 *       - The parseEmotionString function takes each 'emotions' string and turns it into an object. It does this by splitting the string to separate each emotion and its score,
 *       then splitting again to isolate the emotion name and its value, converting the score from a string to a float for accuracy.
 *   3. Transforming Data: The data from each event is turned into a new object that includes an id, the event description, and the parsed emotions object.
 *
 * This transformed data is then stored in the eventsData state variable using the setEventsData function, allowing the component to update and show this data dynamically.
 *
 * Rendering the Data:
 *   - The renderSentiments function goes through the eventsData to create visual representations of each event's emotional data, displaying both text descriptions and any graphical emotion charts.
 *   - If there is data in eventsData, it is given to the SentimentChart component, which makes a chart showing the emotions analysis for each event.
 *   - The results page shows all this information, including both the text and chart, giving viewers a full picture of the emotional analysis of the scenarios.
 */

function Results() {
    const location = useLocation();
    const navigate = useNavigate();

    // State to store the events data
    const [eventsData, setEventsData] = useState([]);

    // Function to parse the emotion string into an object
    function parseEmotionString(emotionString) {
        const emotionObject = {};
        const emotionPairs = emotionString.split(', ');
        emotionPairs.forEach(pair => {
            const [emotion, value] = pair.split(': ');
            if (emotion && value) {
                emotionObject[emotion.trim()] = parseFloat(value.replace(';', ''));
            }
        });
        return emotionObject;
    }
    // useEffect hook to process the data received from the location state
    useEffect(() => {
        // Get the data from the location state
        const data = location.state && location.state.data ? location.state.data : null;

        // If no data is received, log an error and exit early
        if (!data) {
            console.error("No data received");
            return;
        }

        // Transform the incoming data
        const transformedData = data.data.map((item, index) => ({
            id: index,
            event: item.event,
            emotions: parseEmotionString(item.emotions),
        }));
        // Update the eventsData state with the transformed data
        setEventsData(transformedData);
    }, [location]);

    const handleNewScenario = () => {
        navigate(Links.home);
    };

    // Render the sentiments in text form
    const renderSentiments = () => {
        return (
            <div className="affective-forecaster">
                <Header />
                <div className="events">
                    <div>
                        {eventsData.map(({ id, event, emotions }) => (
                            <div key={id} className="event-box">
                                <h4>Event {id + 1}:</h4>
                                <p>{event}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="results">
            <div className="semi-circle-left"></div>
            <div className="semi-circle-right"></div>
            <div className="sentiment-analysis-container">

                {eventsData.length > 0 && (
                    <SentimentChart eventsData={eventsData} />
                )}
                {eventsData.length > 0 ? renderSentiments() : <p>Loading sentiments...</p>}
                <div className="new-scenario-container">
                    <button className="new-scenario-btn" onClick={handleNewScenario}>
                        New Scenario
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Results;
