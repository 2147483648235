class Links{
    static loginpage = "/";
    static home = "/home";
    static test = "/test";
    static results = "/results"
    static signup = "/signup";
    static signin = "/signin";
    static confirmSignup = "/confirmSignup";
}

export default Links;
